<template>
  <!-- 共通PLR基盤データ同期エリア用 -->
  <div>
    <b-button v-if="button" v-on:click="onSyncClick()" variant="csub">
      <b-icon icon="arrow-clockwise"></b-icon>
      PLR基盤との同期
    </b-button>
    <div class="text-csub">
      PLR基盤との最終同期日時 :
      <span v-if="syncDate">{{ syncDate }}</span>
      <span v-else>---</span>
    </div>
    <div v-if="isProcessing" class="text-caccent text-center">
      <b-icon icon="circle-fill" animation="throb"></b-icon>
      PLR基盤とのデータ同期中
    </div>
    <div class="text-danger">{{ syncMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isProcessing: { type: Boolean, default: false },
    syncDate: { type: String, required: false, default: null },
    syncMessage: { type: String, required: false, default: null },
    button: { type: Boolean, default: true },
  },
  methods: {
    onSyncClick() {
      this.$emit('sync-click')
    },
  },
}
</script>
