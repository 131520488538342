<template>
  <!-- 口座一括開設 -->
  <b-container class="py-4">
    <h1>口座一括開設処理</h1>
    <!-- PLR基盤との同期 -->
    <sync-pxr
      v-bind:is-processing="isPxrProcessing"
      v-bind:sync-date="pxrSyncDate"
      v-bind:sync-message="pxrSyncMessage"
      v-bind:button="false"
    />
    <b-form-checkbox v-model="testMode" class="box-large my-2" name="test-mode">
      テストモードで動かす。チェックを入れると「T」のPLR-IDが採番されます。
    </b-form-checkbox>
    <div v-if="testMode" class="text-center text-danger lead">
      テストモードです。「T」のPLR-IDが採番されます。
    </div>
    <section id="waiting-list" class="mb-5">
      <!-- 口座開設待ち一覧 -->
      <b-card no-body>
        <b-card-body>
          <b-card-title title-tag="h2"> 口座開設待ち一覧 </b-card-title>
          <b-card-text class="lead">
            【1】本人性確認
            <b-icon icon="arrow-right"></b-icon> 【2】PLR-ID自動採番
            <b-icon icon="arrow-right"></b-icon>
            【3】口座一括開設（またはCSV出力）
          </b-card-text>
          <div>
            <b-icon icon="info-circle-fill" variant="caccent"></b-icon>
            口座一括開設 : チェックしたものを対象に口座一括開設を行います。
          </div>
          <div>
            <b-icon icon="info-circle-fill" variant="caccent"></b-icon>
            一括開設CSV出力 :
            チェックしたものを対象にポータルの口座一括開設用CSV出力を行います。
            <u>出力後のPLR-ID変更は行わないでください。</u>
          </div>
          <div>
            <b-icon icon="info-circle-fill" variant="csub"></b-icon>
            PLR-ID自動採番 :
            本人性確認OKでPLR-ID未設定のものすべてを対象に自動採番を行います。
          </div>
          <div class="mb-3">
            <b-icon icon="info-circle-fill" variant="csub"></b-icon>
            PLR-IDクリア :
            チェックしたものを対象にPLR-IDを未採番の状態に戻します。
          </div>
          <div v-if="isProcessing">
            <b-alert show variant="caccent" class="text-center">
              <b-icon
                icon="three-dots"
                animation="spin"
                font-scale="2"
                class="align-middle"
              ></b-icon>
              口座一括開設処理中です
            </b-alert>
          </div>
          <div v-else>
            <b-alert
              show
              variant="white"
              class="result-area border"
              v-if="onResultArea"
            >
              <!-- 結果表示用 -->
              <div class="text-right">
                <b-button
                  size="sm"
                  variant="cmain"
                  v-on:click="onResultArea = false"
                  >&times;</b-button
                >
              </div>
              <h3>口座一括開設が完了しました。</h3>
              <b-card no-body class="mb-3">
                <b-card-body>
                  <b-card-title title-tag="h4">失敗した処理</b-card-title>
                  <div v-if="openResultNg.length == 0">なし</div>
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="result in openResultNg"
                      :key="result.plrId"
                    >
                      PLR-ID : {{ result.plrId }} 姓名 : {{ result.surname
                      }}{{ result.firstname }} 結果 :
                      <span class="text-danger">{{ result.exitStatus }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
              <b-card no-body>
                <b-card-body>
                  <b-card-title title-tag="h4">成功した処理</b-card-title>
                  <div v-if="openResultOk.length == 0">なし</div>
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="result in openResultOk"
                      :key="result.plrId"
                    >
                      PLR-ID : {{ result.plrId }} 姓名 : {{ result.surname
                      }}{{ result.firstname }} 結果 :
                      {{ result.exitStatus }}</b-list-group-item
                    >
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-alert>
          </div>
          <b-alert v-if="csvPath1" show variant="csub">
            <a v-bind:href="csvPath1" class="alert-link"
              >CSV（本人性確認書類1枚用）の作成が完了しました {{ csvPath1 }}</a
            >
          </b-alert>
          <b-alert v-if="csvPath2" show variant="csub">
            <a v-bind:href="csvPath2" class="alert-link"
              >CSV（本人性確認書類2枚用）の作成が完了しました {{ csvPath2 }}</a
            >
          </b-alert>
          <b-form-row>
            <b-col cols="6" lg="2">
              <b-button
                variant="csub"
                class="w-100 mb-3"
                v-on:click="setPlrId()"
              >
                PLR-ID自動採番
              </b-button>
            </b-col>
            <b-col cols="6" lg="2">
              <b-button
                variant="outline-csub"
                class="w-100 mb-3"
                v-on:click="clearPlrId()"
              >
                PLR-IDクリア
              </b-button>
            </b-col>
            <b-col cols="6" lg="2">
              <b-button
                variant="caccent"
                class="w-100 mb-3"
                v-on:click="openAccount()"
              >
                口座一括開設
              </b-button>
            </b-col>
            <b-col cols="6" lg="2">
              <b-button
                variant="outline-caccent"
                class="w-100 mb-3"
                v-on:click="getAccountOpeningCsv()"
              >
                一括開設CSV出力
              </b-button>
            </b-col>
            <b-col cols="6" lg="2">
              <b-button
                variant="cmain"
                class="w-100 mb-3"
                v-on:click="getEkycResult()"
              >
                eKYC結果取得
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="4" lg="2" class="mb-3">
              <b-input-group append="件/1ページ">
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  v-bind:options="pageOptions"
                ></b-form-select>
              </b-input-group>
            </b-col>
            <b-col sm="8" lg="6">
              <b-pagination
                v-model="currentPage"
                v-bind:total-rows="totalRows"
                v-bind:per-page="perPage"
                align="fill"
              ></b-pagination>
            </b-col>
          </b-form-row>
          <div class="text-right text-cmain">
            {{ (currentPage - 1) * perPage + 1 }}-
            <span v-if="currentPage * perPage < waitingList.length">
              {{ currentPage * perPage }}
            </span>
            <span v-else>{{ waitingList.length }}</span>
            （{{ waitingList.length }}件中）
          </div>
          <div v-if="isEkycProcessing" class="text-caccent text-center">
            <b-icon icon="circle-fill" animation="throb"></b-icon>
            eKYC身元確認状況取得中
          </div>
          <b-table
            v-bind:items="waitingList"
            v-bind:fields="waitingListFields"
            v-bind:current-page="currentPage"
            v-bind:per-page="perPage"
            responsive
            hover
            bordered
            sticky-header="500px"
            head-variant="dark"
          >
            <template v-slot:head(check)>
              <b-form-checkbox
                v-model="allChecked"
                v-on:change="checkAll()"
                class="box-large"
              ></b-form-checkbox>
            </template>
            <template v-slot:cell(check)="row">
              <b-form-checkbox
                v-bind:value="row.item.applicationId"
                v-model="checked"
                v-bind:disabled="row.item.plrId ? false : true"
                class="box-large"
              ></b-form-checkbox>
            </template>
            <template v-slot:cell(error)="row">
              <span v-if="row.item.error">*</span>
            </template>
            <template v-slot:cell(button)="row">
              <b-button
                variant="csub"
                size="sm"
                class="w-100"
                v-on:click.stop="getIdentifyItems(row.item.applicationId)"
              >
                <span v-if="row.item.ekycPlan">eKYC</span>
                <span v-else>本人性確認</span>
              </b-button>
            </template>
            <template v-slot:cell(contactButton)="row">
              <b-button
                v-b-modal="'modal'"
                variant="caccent"
                size="sm"
                v-on:click.stop="openMessage(row.index, row.item)"
              >
                連絡
              </b-button>
              <div v-if="isModalActive[row.index]">
                <message-modal
                  v-bind:destination="destinationIdList"
                  v-bind:display-name="destinationNameList"
                  v-bind:body-header="bodyHeader"
                  v-bind:target="3"
                  v-bind:template-target="pageId"
                  v-on:close-modal="closeMessage(row.index, $event)"
                />
              </div>
            </template>
            <template v-slot:cell(deleteButton)="row">
              <b-button
                variant="outline-secondary"
                size="sm"
                v-on:click.stop="deleteApplication(row.item.applicationId)"
              >
                削除
              </b-button>
            </template>
          </b-table>
          <p v-if="listMessage" class="text-center text-caccent">
            {{ listMessage }}
          </p>
        </b-card-body>
      </b-card>
    </section>
    <section id="identify-verification">
      <!-- 本人性確認 -->
      <b-card no-body>
        <b-card-body>
          <b-form-row>
            <b-col sm="4" lg="8">
              <b-card-title title-tag="h2">
                本人性確認
                <b class="text-caccent">状況 : {{ identifyStatus }}</b>
              </b-card-title>
            </b-col>
            <b-col cols="6" sm="4" lg="2">
              <b-button
                variant="outline-csub"
                class="w-100 mb-3"
                v-on:click="prevIdentification()"
                v-if="targetId"
              >
                <b-icon icon="chevron-left" class="bg-transparent"></b-icon>
                前へ
              </b-button>
            </b-col>
            <b-col cols="6" sm="4" lg="2">
              <b-button
                variant="outline-csub"
                class="w-100 mb-3"
                v-on:click="nextIdentification()"
                v-if="targetId"
              >
                次へ
                <b-icon icon="chevron-right" class="bg-transparent"></b-icon>
              </b-button>
            </b-col>
          </b-form-row>
          <div v-if="targetId">
            <b-form-row>
              <b-col cols="6" offset-sm="4" sm="4" offset-lg="8" lg="2">
                <b-button
                  variant="caccent"
                  class="w-100 mb-3"
                  v-on:click="checkIdentification(1)"
                  v-bind:disabled="ekycPlan ? true : false"
                >
                  確認OK
                </b-button>
              </b-col>
              <b-col cols="6" sm="4" lg="2">
                <b-button
                  variant="secondary"
                  class="w-100 mb-3"
                  v-on:click="checkIdentification(2)"
                  v-bind:disabled="ekycPlan ? true : false"
                >
                  確認NG
                </b-button>
              </b-col>
            </b-form-row>
            <base-table
              v-bind:items="identifyItems"
              v-bind:fields="identifyItemFields"
            />
            <b-form-group>
              <b-form-radio
                v-model="selectedDocument"
                name="selected-document"
                value="1"
                class="mr-2"
                button
                button-variant="outline-csub"
                v-bind:disabled="!document1.name"
                >本人性確認書類1</b-form-radio
              >
              <b-form-radio
                v-model="selectedDocument"
                name="selected-document"
                value="2"
                button
                button-variant="outline-csub"
                v-bind:disabled="!document2.name"
                >本人性確認書類2</b-form-radio
              >
            </b-form-group>
            <b-card no-body v-if="ekycPlan">
              <b-card-body>
                <b-card-title title-tag="h3">{{ document1.name }}</b-card-title>
                <b-card-text v-if="ekycPlan == 1" class="text-csub lead">
                  公的個人認証
                </b-card-text>
                <b-card-text v-else class="text-csub lead">
                  eKYC「ホ」方式
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-card no-body v-else>
              <b-card-body v-if="selectedDocument == 1">
                <b-card-title title-tag="h3">{{ document1.name }}</b-card-title>
                <b-form-row>
                  <b-col v-if="faceUri">
                    <b-img
                      v-bind:src="faceUri"
                      alt="identification-face"
                      center
                      thumbnail
                      v-on:click="openZoom(faceUri, null)"
                    />
                  </b-col>
                  <b-col>
                    <b-img
                      v-bind:src="document1.front"
                      alt="identification-img11"
                      center
                      thumbnail
                      v-on:click="openZoom(document1.front, document1.back)"
                    />
                  </b-col>
                  <b-col v-if="document1.back">
                    <b-img
                      v-bind:src="document1.back"
                      alt="identification-img12"
                      center
                      thumbnail
                      v-on:click="openZoom(document1.back, document1.front)"
                    />
                  </b-col>
                  <b-col v-else class="text-center">裏面なし</b-col>
                </b-form-row>
              </b-card-body>
              <b-card-body v-else>
                <b-card-title title-tag="h3">{{ document2.name }}</b-card-title>
                <b-form-row>
                  <b-col md="6">
                    <b-img
                      v-bind:src="document2.front"
                      alt="identification-img21"
                      center
                      thumbnail
                      v-on:click="openZoom(document2.front, document2.back)"
                    />
                  </b-col>
                  <b-col md="6" v-if="document2.back">
                    <b-img
                      v-bind:src="document2.back"
                      alt="identification-img22"
                      center
                      thumbnail
                      v-on:click="openZoom(document2.back, document2.front)"
                    />
                  </b-col>
                  <b-col v-else md="6" class="text-center">裏面なし</b-col>
                </b-form-row>
              </b-card-body>
            </b-card>
          </div>
          <div v-else class="text-center text-csub lead">
            口座開設待ち一覧の[本人性確認]ボタンで申請者の本人性確認情報が表示されます。
          </div>
        </b-card-body>
      </b-card>
    </section>
  </b-container>
</template>

<script>
import BaseTable from '@/components/common/BaseTable'
import { openZoom, convertTimezone, getPxrSyncDate } from '@/mixins/utility'
import textGettable from '@/mixins/textGettable'
import SyncPxr from '@/components/common/SyncPxrData'
import MessageModal from '@/components/common/MessageModal'
export default {
  components: {
    BaseTable,
    SyncPxr,
    MessageModal,
  },
  mixins: [openZoom, convertTimezone, textGettable, getPxrSyncDate],
  data() {
    return {
      pageId: 4,
      testMode: false,
      // ***** 口座開設待ち一覧 *****
      waitingList: [],
      waitingListFields: [
        { key: 'check', label: '', tdClass: 'text-center' },
        { key: 'error', label: 'Err', tdClass: 'text-center text-danger' },
        { key: 'plrId', label: 'PLR ID' },
        {
          key: 'identityStatus',
          label: '確認状況',
          tdClass: 'text-center',
        },
        { key: 'button', label: '本人性確認', tdClass: 'text-center' },
        { key: 'surname', label: '姓' },
        { key: 'firstname', label: '名' },
        { key: 'surnameKana', label: '姓よみがな' },
        { key: 'firstnameKana', label: '名よみがな' },
        { key: 'dateOfBirth', label: '生年月日' },
        { key: 'genderText', label: '性別', tdClass: 'text-center' },
        { key: 'address', label: '住所' },
        { key: 'extraAddress', label: '*住所' },
        { key: 'phoneNumber', label: '電話番号' },
        { key: 'email', label: 'メールアドレス' },
        { key: 'receptionType', label: 'コイン受取方法' },
        { key: 'contactButton', label: '連絡', tdClass: 'text-center' },
        { key: 'deleteButton', label: '削除', tdClass: 'text-center' },
        { key: 'errorStatus', label: '開設エラー', tdClass: 'text-danger' },
      ],
      numberedNumber: null, //採番済み数
      checked: [], //checked applicationId list
      allChecked: false,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 30, 50, 100],
      totalRows: 1,
      listMessage: null,
      isModalActive: [], //message
      destinationIdList: [], //message
      destinationNameList: [], //message
      bodyHeader: null, //message
      // ***** 本人性確認項目 *****
      selectedDocument: 1, //書類1,2どちらを表示しているか
      targetId: null,
      document1: {},
      document2: {},
      faceUri: null,
      identifyStatus: null,
      ekycPlan: null,
      identifyItems: [
        {
          surname: null,
          firstname: null,
          dateOfBirth: null,
          dateOfBirthJp: null,
          genderText: null,
          address: null,
          extraAddress: null,
        },
      ],
      identifyItemFields: [
        { key: 'surname', label: '姓' },
        { key: 'firstname', label: '名' },
        { key: 'dateOfBirth', label: '生年月日（西暦）' },
        { key: 'dateOfBirthJp', label: '生年月日（和暦）' },
        { key: 'address', label: '住所' },
        { key: 'extraAddress', label: '*住所' },
        { key: 'genderText', label: '性別' },
      ],
      // ***** 口座一括開設 *****
      openResultOk: [], //結果表示用
      openResultNg: [], //結果表示用
      onResultArea: false, //結果表示用
      isProcessing: false, //処理中
      // ***** 口座一括開設用csv *****
      csvPath1: null,
      csvPath2: null,
      // ***** PLR基盤との同期 *****
      isPxrProcessing: false,
      pxrSyncDate: null,
      pxrSyncMessage: null,
      // ***** eKYC状況取得 *****
      isEkycProcessing: false,
    }
  },
  watch: {
    waitingList(val) {
      console.log('** watch items **', val)
      this.checked = []
      this.allChecked = false
      this.totalRows = this.waitingList.length
      const array = new Array(this.perPage).fill(false)
      this.isModalActive = array
    },
    checked(val) {
      console.log('** watch checked **', val)
      if (val.length == 0) {
        this.allChecked = false
      } else if (val.length == this.numberedNumber) {
        // 採番済みすべてにチェックが付いたら
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    },
    perPage(val) {
      console.log('** watch perPage **', val)
      const array = new Array(val).fill(false)
      this.isModalActive = array
    },
  },
  created() {
    // 最終同期日時取得
    this.getPxrSyncDate(this.pageId).then((ret) => {
      this.pxrSyncDate = this.convertTimezone(ret, false)
      if (this.$store.getters.pxrSyncAccountOpening) {
        // 口座開設待ち一覧取得
        this.getAccountWaiting()
      } else {
        // データ同期
        this.syncPxrData()
      }
    })
  },
  methods: {
    syncPxrData() {
      // PLR基盤とのデータ同期
      console.log('** pxr data sync **')
      this.pxrSyncMessage = null
      this.isPxrProcessing = true
      this.$axiosToken
        .post('/account_opening/pxr_data')
        .then((response) => {
          console.log(response)
          // storeに同期したことを保存
          this.$store.dispatch('setPxrSync', { target: 'accountOpening' })
          // 同期日時取得
          this.getPxrSyncDate(this.pageId).then(
            (ret) => (this.pxrSyncDate = this.convertTimezone(ret, false))
          )
        })
        .catch((error) => {
          console.log(error)
          this.pxrSyncMessage =
            new Date().toLocaleString() + ' PLR基盤との同期に失敗しました。'
          if (error.response) {
            console.log(error.response)
            this.pxrSyncMessage =
              this.pxrSyncMessage + error.response.data.detail
          }
        })
        .finally(() => {
          this.isPxrProcessing = false
          // 口座開設待ち一覧取得
          this.getAccountWaiting()
        })
    },
    checkAll() {
      // すべて選択CheckBox
      if (this.allChecked) {
        // plrIdがセットされているものすべてチェック
        const targetItems = this.waitingList.filter((item) => item.plrId)
        this.checked = targetItems.map((item) => item.applicationId)
      } else {
        this.checked = []
      }
    },
    getAccountWaiting() {
      // 口座開設待ち一覧取得
      console.log('* getAccountWaiting *')
      this.$axiosToken
        .get('/account_opening/personal_info')
        .then((response) => {
          console.log(response)
          this.waitingList = response.data.personalInfoList
          // codeで取得するものを画面表示用テキストに変換
          for (let i = 0; i < this.waitingList.length; i++) {
            this.waitingList[i].identityStatus = this.getText(
              'identityStatus',
              this.waitingList[i].identityVerificationStatus
            )
            this.waitingList[i].genderText = this.getText(
              'gender',
              this.waitingList[i].gender
            )
          }
          // 採番済み数おいておく
          this.numberedNumber = this.waitingList.filter(
            (item) => item.plrId
          ).length
          if (this.waitingList.length == 0) {
            this.listMessage = '口座開設待ちはありません。'
          }
        })
        .catch((error) => {
          console.log(error)
          this.waitingList = []
          this.numberedNumber = null
          this.listMessage = '口座開設待ち一覧の取得に失敗しました。'
        })
    },
    getIdentifyItems(applicationId, initSelectedDocument = true) {
      // 本人性確認に必要な項目を取得
      console.log('* getIdentifyItems application id:', applicationId)
      this.$axiosToken
        .get('/account_opening/personal_info/' + applicationId)
        .then((response) => {
          console.log(response)
          this.identifyStatus = this.getText(
            'identityStatus',
            response.data.identityVerificationStatus
          )
          this.identifyItems[0].surname = response.data.surname
          this.identifyItems[0].firstname = response.data.firstname
          this.identifyItems[0].dateOfBirth = response.data.dateOfBirth
          this.identifyItems[0].dateOfBirthJp = response.data.dateOfBirthJp
          this.identifyItems[0].genderText = this.getText(
            'gender',
            response.data.gender
          )
          this.identifyItems[0].address = response.data.address
          this.identifyItems[0].extraAddress = response.data.extraAddress
          this.document1 = response.data.document1
          this.document2 = response.data.document2
          this.faceUri = response.data.faceImage
          this.ekycPlan = response.data.ekycPlan
          if (initSelectedDocument) {
            this.selectedDocument = 1
          }
          this.targetId = applicationId
        })
        .catch((error) => {
          console.log(error)
          alert('本人性確認情報の取得に失敗しました。')
          // 前回分は表示したままにする
        })
    },
    setPlrId() {
      // PLR-ID自動採番
      console.log('** set PLR-ID **')
      this.$axiosToken
        .post('/account_opening/obtain_plr_ids?test=' + this.testMode)
        .then((response) => {
          console.log('* plrId numbered *', response)
          if (response.data.numberOfProcessed == 0) {
            alert(
              '対象が1件もありませんでした。本人性確認OKのものが対象となります。'
            )
          } else {
            // 一覧更新
            this.getAccountWaiting()
          }
        })
        .catch((error) => {
          console.log(error)
          alert(' PLR-IDの自動採番に失敗しました。')
        })
    },
    clearPlrId() {
      // PLR-IDクリア
      console.log('** clear PLR-ID **', this.checked)
      if (this.checked.length == 0) {
        alert('PLR-IDをクリアする申込みを一つ以上選択してください。')
        return
      }
      this.$axiosToken
        .post('/account_opening/remove_plr_ids', {
          idList: this.checked,
        })
        .then((response) => {
          console.log(response)
          // 一覧更新
          this.getAccountWaiting()
        })
        .catch((error) => {
          console.log(error)
          alert('PLR-IDのクリアに失敗しました。')
        })
    },
    getAccountOpeningCsv() {
      // 口座一括開設用のcsvを出力する
      console.log('** get opening csv **', this.checked)
      this.csvPath1 = null
      this.csvPath2 = null
      if (this.checked.length == 0) {
        alert('csv作成する申込みを一つ以上選択してください。')
        return
      }
      this.$axiosToken
        .post('/account_opening/all_openings_csv', {
          applicationIdList: this.checked,
        })
        .then((response) => {
          console.log(response)
          console.log(response.data.csvFiles)
          for (let i = 0; i < response.data.csvFiles.length; i++) {
            if (response.data.csvFiles[i].docType == 1) {
              this.csvPath1 = response.data.csvFiles[i].uri
            } else {
              this.csvPath2 = response.data.csvFiles[i].uri
            }
          }
        })
        .catch((error) => {
          console.log(error)
          alert('CSVの出力に失敗しました。')
        })
    },
    openAccount() {
      // 口座一括開設
      console.log('** open account **', this.checked)
      if (this.checked.length == 0) {
        alert('口座開設する申込みを一つ以上選択してください。')
        return
      }
      this.onResultArea = false
      this.openResultOk = []
      this.openResultNg = []
      this.isProcessing = true
      this.csvPath1 = null
      this.csvPath2 = null
      this.$axiosToken
        .post('/account_opening/open_mcb', {
          applicationIdList: this.checked,
        })
        .then((response) => {
          console.log(response)
          const resultList = response.data.exitStatusList
          this.openResultOk = resultList.filter(
            (item) => item.exitStatus == 'ok'
          )
          this.openResultNg = resultList.filter(
            (item) => item.exitStatus != 'ok'
          )
          this.onResultArea = true

          // 一覧更新
          this.getAccountWaiting()
          // 確認エリアはクリア
          this.targetId = null
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            if (error.response.status == 504) {
              alert(
                '504 Gateway Time-out タイムアウトが発生しました。\n結果表示はされません。\nこのメッセージを閉じた後、一覧に表示されないものは、口座開設完了しています。ご確認ください。\n口座開設済みが一覧に残っている場合は、画面をリロードしてください。'
              )
              // 一覧更新
              this.getAccountWaiting()
              // 確認エリアはクリア
              this.targetId = null
            } else {
              alert(error.response.data.detail)
            }
          } else {
            alert('口座一括開設に失敗しました。')
          }
        })
        .finally(() => (this.isProcessing = false))
    },
    checkIdentification(result) {
      // 本人性確認
      console.log('** checkIdentification **', result)
      this.$axiosToken
        .put('/account_opening/identify/' + this.targetId, {
          identityVerificationStatus: result,
        })
        .then((response) => {
          console.log(response)
          // 一覧更新
          this.getIdentifyItems(this.targetId, false)
          this.getAccountWaiting()
        })
        .catch((error) => {
          console.log(error)
          alert('本人性確認の保存に失敗しました。')
        })
    },
    nextIdentification() {
      // 次の本人性確認へ
      const index = this.waitingList.findIndex(
        ({ applicationId }) => applicationId === this.targetId
      )
      if (index + 1 >= this.waitingList.length) {
        alert('最後の申し込みです。')
        return
      }
      const nextApplicationId = this.waitingList[index + 1].applicationId
      this.getIdentifyItems(nextApplicationId)
    },
    prevIdentification() {
      // 前の本人性確認へ
      const index = this.waitingList.findIndex(
        ({ applicationId }) => applicationId === this.targetId
      )
      if (index == 0) {
        alert('先頭の申し込みです。')
        return
      }
      const prevApplicationId = this.waitingList[index - 1].applicationId
      this.getIdentifyItems(prevApplicationId)
    },
    deleteApplication(applicationId) {
      // 申込の削除
      console.log('* delete application id:', applicationId)
      this.$axiosToken
        .delete('/account_opening/personal_info/' + applicationId)
        .then((response) => {
          console.log(response)
          // 確認選択されているものが削除された場合
          if (applicationId == this.targetId) {
            this.targetId = null
          }
          // 一覧更新
          this.getAccountWaiting()
        })
        .catch((error) => {
          console.log(error)
          alert('削除に失敗しました。')
        })
    },
    openMessage(index, item) {
      // message送信画面を開く
      console.log('** open modal **', index, item)
      this.destinationIdList.push(item.applicationId)
      this.destinationNameList.push(item.surname + item.firstname)
      this.bodyHeader = item.surname + ' ' + item.firstname + ' 様'
      this.isModalActive.splice(index, 1, true)
    },
    closeMessage(index, event) {
      // message送信画面を閉じたとき
      console.log('** close modal **', index, event)
      this.destinationIdList = []
      this.destinationNameList = []
      this.isModalActive.splice(index, 1, false)
    },
    getEkycResult() {
      // eKYC身元確認状況取得
      console.log('** get ekyc result **')
      this.isEkycProcessing = true
      this.$axiosToken
        .post('/account_opening/ekyc_verification_result')
        .then((response) => {
          console.log(response)
          // 一覧更新
          this.getAccountWaiting()
          // 確認エリアはクリア
          this.targetId = null
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('eKYC結果取得に失敗しました。')
          }
        })
        .finally(() => {
          this.isEkycProcessing = false
        })
    },
  },
}
</script>

<style scoped>
.result-area .card {
  max-height: 150px;
  overflow: auto;
}
</style>
