<template>
  <!-- 共通table用 option:filter/pagination/select/sort -->
  <div>
    <b-form-row>
      <b-col v-if="isFilter" lg="6">
        <b-input-group class="mb-3">
          <b-input-group-prepend is-text>
            <b-icon icon="filter" variant="csub"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchText"
            placeholder="Filter"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col
        v-if="isPagination"
        sm="4"
        v-bind:offset-lg="isFilter ? 0 : 6"
        lg="2"
        class="mb-3"
      >
        <b-input-group append="件/1ページ">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            v-bind:options="pageOptions"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col v-if="isPagination" sm="8" lg="4">
        <b-pagination
          v-model="currentPage"
          v-bind:total-rows="totalRows"
          v-bind:per-page="perPage"
          align="fill"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <div class="text-right text-cmain">
      <div v-if="isPagination">
        {{ (currentPage - 1) * perPage + 1 }}-
        <span v-if="currentPage * perPage < totalRows">
          {{ currentPage * perPage }}
        </span>
        <span v-else>{{ totalRows }}</span>
        （{{ totalRows }}件中）
      </div>
      <div v-else-if="showTotalCount">（全{{ totalRows }}件）</div>
    </div>
    <b-table
      v-bind:items="items"
      v-bind:fields="fields"
      v-bind:sort-by.sync="sortBy"
      v-bind:sort-desc.sync="sortDesc"
      v-bind:current-page="currentPage"
      v-bind:per-page="isPagination ? perPage : 0"
      v-bind:filter="searchText"
      v-bind:selectable="isSelectable"
      v-bind:hover="isSelectable"
      responsive
      bordered
      v-bind:sticky-header="tableHeight"
      head-variant="dark"
      select-mode="single"
      v-on:filtered="onFiltered"
      v-on:row-selected="onRowSelected"
    >
      <!-- カスタム列用 -->
      <template v-for="field in slotFields" v-slot:[`cell(${field.key})`]="row">
        <slot v-bind:name="field.slotName" v-bind="row"></slot>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: { default: () => [] },
    fields: { default: () => [] },
    isFilter: { type: Boolean, default: false },
    isPagination: { type: Boolean, default: false },
    isSelectable: { type: Boolean, default: false },
    showTotalCount: { type: Boolean, default: false },
    defaultSort: { default: () => ({ by: null, desc: true }) },
    tableHeight: { type: String, default: '500px' },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 30, 50, 100],
      totalRows: 0,
      searchText: null,
      sortBy: this.defaultSort.by,
      sortDesc: this.defaultSort.desc,
    }
  },
  computed: {
    slotFields() {
      return this.fields.filter((f) => f.slotName)
    },
  },
  watch: {
    items(val) {
      console.log('** watch items **', val)
      this.totalRows = this.items.length
      this.currentPage = 1
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {
    onRowSelected(item) {
      console.log('** row selected **', item[0])
      this.$emit('row-selected', item[0])
    },
    onFiltered(filteredItems) {
      console.log('** filter on **', filteredItems)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
