export default {
  // 各codeの画面表示用テキスト
  data() {
    return {
      constants: {
        cooperationStatus: [
          { text: '連携待ちあり', value: 0 },
          { text: '連携中あり', value: 1 },
          { text: '解除待ちあり', value: 2 },
        ],
        identityStatus: [
          { text: '済-OK', value: 1 },
          { text: '済-NG', value: 2 },
          { text: '未', value: 3 },
        ],
        gender: [
          { text: '男', value: 1 },
          { text: '女', value: 2 },
          // { text: '選択しない', value: 3 },
        ],
        issuanceForm: [
          { text: '契約書', value: 1 },
          { text: '見積書', value: 2 },
          { text: '請求書', value: 3 },
        ],
        userRole: [
          { text: '管理者', value: 0 },
          { text: 'iLDi', value: 1 },
          { text: 'コンソーシアム', value: 2 },
          { text: '阪大会計担当', value: 3 },
        ],
      },
    }
  },
  methods: {
    getText(target, value) {
      // 表示用テキストを取得する
      if (this.constants[target].some((v) => v.value === value)) {
        return this.constants[target].find((v) => v.value === value).text
      } else {
        return null
      }
    },
  },
}
