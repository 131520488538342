<template>
  <!-- メッセージ送信モーダル画面 -->
  <b-modal
    id="modal"
    size="lg"
    title="メッセージ送信"
    header-bg-variant="cmain"
    header-text-variant="white"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
  >
    <b-alert v-if="outputMsg" show variant="caccent">
      <div style="white-space: pre-wrap" v-text="outputMsg" />
    </b-alert>
    <p v-if="isErroTemplate" class="text-caccent">
      テンプレートの取得に失敗しました
    </p>
    <b-form v-on:submit.prevent="sendMessage()">
      <b-form-group label="テンプレート選択" label-for="message-template">
        <b-select
          id="message-template"
          v-model="templateSelected"
          v-on:change="setTemplate()"
        >
          <option
            v-for="template in templates"
            v-bind:value="template"
            v-bind:key="template.name"
          >
            {{ template.name }}
          </option>
        </b-select>
      </b-form-group>
      <b-form-group label="送信先" label-for="message-name">
        <b-form-input
          id="message-name"
          v-model="displayNameStr"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label="タイトル" label-for="message-title">
        <b-form-input
          id="message-title"
          v-model="title"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="メッセージ" label-for="message-body">
        <b-form-textarea
          id="message-body"
          v-model="body"
          rows="12"
          required
        ></b-form-textarea>
      </b-form-group>
      <p v-if="target == 3" class="text-muted">
        タイトル・メッセージで使用可能な置き換え文字 姓:
        <mark>%surname%</mark> 名:
        <mark>%firstname%</mark>
      </p>
      <p class="text-csub">
        送り先がLINEの場合は、タイトルとメッセージが合わせて送信されます。
      </p>
      <p class="text-csub">
        一括送信の場合は、それぞれの宛先に個別に送信されます。
      </p>
      <b-form-row>
        <b-col offset-sm="3" sm="3">
          <b-button
            class="w-100 mb-3"
            variant="caccent"
            type="submit"
            v-bind:disabled="isSent"
          >
            送信
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button v-on:click="onCloseClick" class="w-100 mb-3" variant="csub">
            閉じる
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: [
    'destination',
    'displayName',
    'target',
    'templateTarget',
    'bodyHeader',
  ],
  data() {
    return {
      title: '',
      body: '',
      isSent: false,
      displayNameStr: this.displayName.join(),
      outputMsg: '',
      templates: [],
      templateSelected: [],
      isErroTemplate: false,
    }
  },
  created() {
    this.getTemplate()
    this.body = this.bodyHeader + '\n\n'
  },
  methods: {
    getTemplate() {
      // テンプレート取得
      const templateTarget = this.templateTarget
        ? this.templateTarget
        : this.target
      this.$axiosToken
        .get('/api/message/' + templateTarget)
        .then((response) => {
          console.log('** get template **', response)
          this.templates = response.data.messageTemplateList
        })
        .catch((error) => {
          console.log(error)
          this.isErroTemplate = true
        })
    },
    setTemplate() {
      // 選択したテンプレートをセット
      this.title = this.templateSelected.title
      this.body = this.bodyHeader + '\n\n' + this.templateSelected.body
    },
    sendMessage() {
      // message送信
      console.log(this.target, this.destination)
      this.$axiosToken
        .post('/api/message', {
          target: this.target,
          idList: this.destination,
          title: this.title,
          body: this.body,
        })
        .then((response) => {
          console.log('** send message **', response)
          const resultSent = response.data.sent
          const resultFailed = response.data.failed
          if (resultFailed.length == 0) {
            this.outputMsg = 'メッセージの送信が完了しました。'
          } else if (resultSent.length == 0) {
            this.outputMsg = 'メッセージの送信に失敗しました。'
          } else {
            this.outputMsg = '一部送信先へのメッセージ送信に失敗しました。'
            this.outputMsg =
              this.outputMsg + '\n【失敗】' + resultFailed.join(', ')
            this.outputMsg =
              this.outputMsg + '\n【成功】' + resultSent.join(', ')
          }

          if (resultSent.length > 0) {
            this.isSent = true
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.outputMsg = 'エラー: ' + error.response.data.detail
          } else {
            this.outputMsg = 'エラー: メッセージの送信に失敗しました。'
          }
        })
    },
    onCloseClick() {
      // 画面を閉じるときmessage送信したか返す
      this.$emit('close-modal', this.isSent)
    },
  },
}
</script>
